<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Language</h3>

    <LanguageYN v-model="form.clientData.language_status"/>
    <LanguageDetails v-if="languageIssues(form.clientData)" v-model="form.clientData.language_issues"/>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import { dateFormat } from '@/mixins/dateFormat';

import { showClientQuestionHelpers} from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";

import LanguageYN from "../../question/questions/estatePlanningSurvey/health/LanguageYN";
import LanguageDetails from "../../question/questions/estatePlanningSurvey/health/LanguageDetails";

export default {
  name: 'Language',
  mixins: [fieldsetHelpers, showClientQuestionHelpers, dateFormat],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    LanguageDetails,
    LanguageYN
  },
  data() {
    return {
      label: 'Language' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
